import useCompare, { Product } from '@licommon/hooks/compare/useCompare'
import { clsx } from '@licommon/utils/clsx'
import { getPortalType } from '@licommon/utils/portal'
import Button from '../Button'
import { ListBullets, Plus } from '../Icons'

interface AddToCompareButtonProps {
  product: Product
  showIcon?: boolean
  className?: string
  addedBtnText?: string
  addedBtnClassName?: string
  iconOnly?: boolean
}

const AddToCompareButton = ({
  product,
  className,
  showIcon = true,
  addedBtnText = 'Remove from Compare',
  addedBtnClassName = '',
  iconOnly = false,
  ...props
}: AddToCompareButtonProps) => {
  const { addToCompare, removeFromCompare, isProductInCompare } = useCompare()
  const isAdded = isProductInCompare(product)
  const handleAddToCompare = (e) => {
    e.preventDefault()
    e.stopPropagation()
    if (isAdded) {
      removeFromCompare(product)
    } else {
      addToCompare(product)
    }
  }
  const { isCALIBO } = getPortalType()
  return (
    <Button
      flat
      className={clsx(
        `${isAdded && `bg-gray-100 ${addedBtnClassName}`}`,
        className,
      )}
      onClick={handleAddToCompare}
    >
      {showIcon && !isCALIBO ? (
        <ListBullets className={`${isAdded && 'text-primary'}`}></ListBullets>
      ) : showIcon && isCALIBO ? (
        <Plus className={`${isAdded && 'text-primary'}`} />
      ) : null}
      {!iconOnly && (isAdded ? addedBtnText : 'Compare')}
    </Button>
  )
}

export default AddToCompareButton
