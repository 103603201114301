import { clsx } from "@licommon/utils/clsx";
import Image from "next/image";
import { AssetOverlay, OverlayPosition } from "../types";

function positionToClass(
  position: OverlayPosition,
  type: "details" | "thumbnail"
): string {
  switch (position) {
    case "top-left":
      return type == "thumbnail" ? "top-10 left-4" : "top-4 left-4";
    case "top-center":
      return type == "thumbnail"
        ? "top-10 left-1/2 -translate-x-1/2"
        : "top-4 left-1/2 -translate-x-1/2";
    case "top-right":
      return type == "thumbnail" ? "top-10 right-4" : "top-4 right-4";
    case "middle-left":
      return "top-1/2 left-4 -translate-y-1/2";
    case "middle-center":
      return "top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2";
    case "middle-right":
      return "top-1/2 right-4 -translate-y-1/2";
    case "bottom-left":
      return type == "thumbnail" ? "bottom-10 left-4" : "bottom-4 left-4";
    case "bottom-center":
      return type == "thumbnail"
        ? "bottom-10 left-1/2 -translate-x-1/2"
        : "bottom-4 left-1/2 -translate-x-1/2";
    case "bottom-right":
      return type == "thumbnail" ? "bottom-10 right-4" : "bottom-4 right-4";
    default:
      // Handle any additional or invalid positions
      return "";
  }
}
export default function ImageOverlays({
  overlays = [],
  type = "details",
}: {
  type: "details" | "thumbnail";
  overlays: AssetOverlay[];
}) {
  return (
    <>
      {overlays.map((overlay) => {
        return (
          <Image
            className={clsx(
              "absolute z-10",
              type == "details" &&
                "w-[50px] h-[50px] sm:w-[70px] sm:h-[70px] xl:h-[100px] xl:w-[100px]",
              type == "thumbnail" &&
                "w-[32px] h-[32px] xl:w-[40px] xl:h-[40px]",
              positionToClass(overlay.position, type)
            )}
            height={100}
            width={100}
            src={overlay.image.url}
            alt={overlay.title}
            loading="eager"
            priority={true}
          />
        );
      })}
    </>
  );
}
