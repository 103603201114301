'use client'
import { useMemo } from 'react'
import { useAppSelector } from '../store'

const useIsWishlisted = (id: number | string) => {
  const wishlisted = useAppSelector((state) => state.wishlistPopup.ids)
  const isInWishlist = useMemo(() => {
    // @ts-ignore
    return wishlisted.includes(parseInt(id))
  }, [wishlisted])
  return isInWishlist
}
export default useIsWishlisted

