import Modal from '../Modal'
import { useMemo } from 'react'
import AuthTab from './AuthTab'

export default function AuthFullModal({
  show,
  onClose,
  onSuccess,
  title,
  initialData,
  type = 'login',
  redirectSocialLogin,
}: {
  show: boolean
  onClose: () => void
  onSuccess?: () => void
  title?: string
  initialData?: { email?: string; disablePromotion?: boolean }
  type?: 'login' | 'register'
  redirectSocialLogin?: string
}) {
  return (
    <Modal
      show={show}
      containerClassName="max-w-4xl w-full"
      onClose={onClose}
      title={title}
      titleClassName="md:text-lg font-bold"
    >
      <AuthTab
        type={type}
        showTab={true}
        mainTitle="Login or Create an Account"
        showSocialOptions={true}
        onSuccess={onSuccess}
        onSuccessReload={false}
        initialData={initialData}
        redirectSocialLogin={redirectSocialLogin}
      ></AuthTab>
    </Modal>
  )
}
